import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { throttle } from 'lodash';

// CSS
import './App.css';

// HOOKS
import useWindowSize from './hooks/browser/useWindowSize';

// COMPONENTS

// HELPERS
import { getWindowSizes, isDesktopDevice } from './helpers/browser';

const iconScale = 1.2;
const backgroundShiftPercentage = 0.05;

const HEADER = 'EL CHOOP';
const LINKS = [
  {
    id: 'soundcloud',
    text: 'Soundcloud',
    href: 'https://soundcloud.com/el_choop'
  },
  {
    id: 'instagram',
    text: 'Instagram',
    href: 'https://www.instagram.com/el_choop/'
  },
  {
    id: 'ra',
    text: 'Resident Advisor',
    href: 'https://www.residentadvisor.net/dj/elchoop'
  },
  {
    id: 'facebook',
    text: 'Facebook',
    href: 'https://www.facebook.com/elchoop'
  },
  {
    id: 'youtube',
    text: 'Youtube',
    href: 'https://www.youtube.com/results?search_query=elchoop'
  },
  {
    id: 'twitter',
    text: 'Twitter',
    href: 'https://twitter.com/el_choop'
  },
    // {
  //   id: 'discogs',
  //   text: 'Discogs',
  //   href: 'https://www.discogs.com/artist/5012359-El-Choop'
  // },
];
const ICONS = {
  instagram: (
    <svg
      width={24 * iconScale}
      viewBox="0 0 511 511.9"
      xmlns="http://www.w3.org/2000/svg"
      fill='white'
    >
      <path d="M510.95 150.5c-1.2-27.2-5.598-45.898-11.9-62.102-6.5-17.199-16.5-32.597-29.6-45.398-12.802-13-28.302-23.102-45.302-29.5-16.296-6.3-34.898-10.7-62.097-11.898C334.648.3 325.949 0 256.449 0s-78.199.3-105.5 1.5c-27.199 1.2-45.898 5.602-62.097 11.898-17.204 6.5-32.602 16.5-45.403 29.602-13 12.8-23.097 28.3-29.5 45.3-6.3 16.302-10.699 34.9-11.898 62.098C.75 177.801.449 186.5.449 256s.301 78.2 1.5 105.5c1.2 27.2 5.602 45.898 11.903 62.102 6.5 17.199 16.597 32.597 29.597 45.398 12.801 13 28.301 23.102 45.301 29.5 16.3 6.3 34.898 10.7 62.102 11.898 27.296 1.204 36 1.5 105.5 1.5s78.199-.296 105.5-1.5c27.199-1.199 45.898-5.597 62.097-11.898a130.934 130.934 0 0074.903-74.898c6.296-16.301 10.699-34.903 11.898-62.102 1.2-27.3 1.5-36 1.5-105.5s-.102-78.2-1.3-105.5zm-46.098 209c-1.102 25-5.301 38.5-8.801 47.5-8.602 22.3-26.301 40-48.602 48.602-9 3.5-22.597 7.699-47.5 8.796-27 1.204-35.097 1.5-103.398 1.5s-76.5-.296-103.403-1.5c-25-1.097-38.5-5.296-47.5-8.796C94.551 451.5 84.45 445 76.25 436.5c-8.5-8.3-15-18.3-19.102-29.398-3.5-9-7.699-22.602-8.796-47.5-1.204-27-1.5-35.102-1.5-103.403s.296-76.5 1.5-103.398c1.097-25 5.296-38.5 8.796-47.5C61.25 94.199 67.75 84.1 76.352 75.898c8.296-8.5 18.296-15 29.398-19.097 9-3.5 22.602-7.7 47.5-8.801 27-1.2 35.102-1.5 103.398-1.5 68.403 0 76.5.3 103.403 1.5 25 1.102 38.5 5.3 47.5 8.8 11.097 4.098 21.199 10.598 29.398 19.098 8.5 8.301 15 18.301 19.102 29.403 3.5 9 7.699 22.597 8.8 47.5 1.2 27 1.5 35.097 1.5 103.398s-.3 76.301-1.5 103.301zm0 0" />
      <path d="M256.45 124.5c-72.598 0-131.5 58.898-131.5 131.5s58.902 131.5 131.5 131.5c72.6 0 131.5-58.898 131.5-131.5s-58.9-131.5-131.5-131.5zm0 216.8c-47.098 0-85.302-38.198-85.302-85.3s38.204-85.3 85.301-85.3c47.102 0 85.301 38.198 85.301 85.3s-38.2 85.3-85.3 85.3zm0 0M423.852 119.3c0 16.954-13.747 30.7-30.704 30.7-16.953 0-30.699-13.746-30.699-30.7 0-16.956 13.746-30.698 30.7-30.698 16.956 0 30.703 13.742 30.703 30.699zm0 0" />
    </svg>
  ),
  youtube: (
    <svg
      width={28 * iconScale}
      viewBox="-21 -117 682.667 682"
      xmlns="http://www.w3.org/2000/svg"
      fill='white'
    >
      <path d="M626.813 64.035c-7.375-27.418-28.993-49.031-56.407-56.414C520.324-6.082 319.992-6.082 319.992-6.082s-200.324 0-250.406 13.184c-26.887 7.375-49.031 29.52-56.406 56.933C0 114.113 0 217.97 0 217.97s0 104.379 13.18 153.933c7.382 27.414 28.992 49.028 56.41 56.41C120.195 442.02 320 442.02 320 442.02s200.324 0 250.406-13.184c27.418-7.379 49.032-28.992 56.414-56.406 13.176-50.082 13.176-153.934 13.176-153.934s.527-104.383-13.183-154.46zM256.21 313.915V122.022l166.586 95.946zm0 0" />
    </svg>
  ),
  ra: (
    <svg viewBox="0 0 83 40" color="primary" width={34 * iconScale}>
      <g fill='white' fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path d="M82.092 32.018c.556-.533.908-1.28.908-2.113 0-.802-.38-1.523-.9-2.051L58.665 4.3l-7.073 7.11 18.45 18.543h-26.14c-1.278-.038-2.29-.469-3.147-1.304l-11.73-11.788a6.828 6.828 0 00-4.689-1.888l-.017.001H10.004v-4.92h14.825c2.938.002 5.559 1.21 7.48 3.15l8.749 8.793 7.073-7.11-8.92-8.963C35.485 2.234 30.45 0 24.805 0H0v25.027h20.978v.002a4.919 4.919 0 013.486 1.48L35.95 38.053A6.74 6.74 0 0040.449 40h31.733a4.911 4.911 0 003.423-1.45l6.491-6.524-.004-.008" />
      </g>
    </svg>
  ),
  soundcloud: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={30 * iconScale}
      viewBox="0 208.952 1048.713 581.696"
      fill='white'
    >
      <path d="M0 686.216c0 13.014 4.718 22.854 14.152 29.524 9.435 6.669 19.52 9.027 30.256 7.076 10.085-1.952 17.161-5.531 21.229-10.736 4.066-5.205 6.1-13.827 6.1-25.864v-141.52c0-10.086-3.497-18.626-10.492-25.62-6.994-6.995-15.534-10.492-25.62-10.492-9.76 0-18.137 3.497-25.132 10.492C3.498 526.07 0 534.61 0 544.696v141.52zm112.24 60.512c0 9.436 3.335 16.511 10.004 21.229 6.67 4.718 15.21 7.076 25.62 7.076 10.736 0 19.438-2.359 26.108-7.076 6.669-4.717 10.004-11.793 10.004-21.229V416.84c0-9.76-3.498-18.138-10.492-25.132-6.995-6.994-15.535-10.492-25.62-10.492-9.76 0-18.138 3.498-25.132 10.492-6.995 6.995-10.492 15.372-10.492 25.132v329.888zm111.752 15.616c0 9.435 3.416 16.511 10.248 21.229 6.832 4.717 15.616 7.076 26.353 7.076 10.41 0 18.95-2.359 25.619-7.076 6.67-4.718 10.005-11.794 10.005-21.229V461.248c0-10.085-3.498-18.707-10.492-25.864-6.995-7.157-15.372-10.735-25.132-10.735-10.086 0-18.707 3.578-25.864 10.735s-10.736 15.779-10.736 25.864v301.096zm112.24 1.464c0 17.894 12.037 26.841 36.112 26.841 24.074 0 36.111-8.947 36.111-26.841v-488c0-27.328-8.296-42.781-24.888-46.36-10.736-2.603-21.31.488-31.72 9.272-10.411 8.784-15.616 21.146-15.616 37.088v488zm114.193 14.152V247.016c0-16.917 5.042-27.002 15.128-30.256 21.797-5.205 43.432-7.808 64.904-7.808 49.775 0 96.136 11.712 139.079 35.136 42.944 23.424 77.674 55.388 104.188 95.892 26.515 40.505 41.887 85.156 46.116 133.957 19.845-8.459 40.991-12.688 63.439-12.688 45.547 0 84.506 16.104 116.876 48.312 32.371 32.209 48.557 70.923 48.557 116.145 0 45.547-16.186 84.424-48.557 116.632-32.37 32.208-71.166 48.312-116.388 48.312l-424.56-.488c-2.929-.976-5.125-2.766-6.589-5.368s-2.193-4.882-2.193-6.834z" />
    </svg>
  ),
  facebook: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 167.657 167.657"
      fill='white'
      width={24 * iconScale}
    >
      <path
        d="M83.829.349C37.532.349 0 37.881 0 84.178c0 41.523 30.222 75.911 69.848 82.57v-65.081H49.626v-23.42h20.222V60.978c0-20.037 12.238-30.956 30.115-30.956 8.562 0 15.92.638 18.056.919v20.944l-12.399.006c-9.72 0-11.594 4.618-11.594 11.397v14.947h23.193l-3.025 23.42H94.026v65.653c41.476-5.048 73.631-40.312 73.631-83.154 0-46.273-37.532-83.805-83.828-83.805z"
      />
    </svg>
  ),
  twitter: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={26 * iconScale} fill='white'>
      <path d="M512 97.248c-19.04 8.352-39.328 13.888-60.48 16.576 21.76-12.992 38.368-33.408 46.176-58.016-20.288 12.096-42.688 20.64-66.56 25.408C411.872 60.704 384.416 48 354.464 48c-58.112 0-104.896 47.168-104.896 104.992 0 8.32.704 16.32 2.432 23.936-87.264-4.256-164.48-46.08-216.352-109.792-9.056 15.712-14.368 33.696-14.368 53.056 0 36.352 18.72 68.576 46.624 87.232-16.864-.32-33.408-5.216-47.424-12.928v1.152c0 51.008 36.384 93.376 84.096 103.136-8.544 2.336-17.856 3.456-27.52 3.456-6.72 0-13.504-.384-19.872-1.792 13.6 41.568 52.192 72.128 98.08 73.12-35.712 27.936-81.056 44.768-130.144 44.768-8.608 0-16.864-.384-25.12-1.44C46.496 446.88 101.6 464 161.024 464c193.152 0 298.752-160 298.752-298.688 0-4.64-.16-9.12-.384-13.568 20.832-14.784 38.336-33.248 52.608-54.496z" />
    </svg>
  ),
  email: (isSmall) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 184 184" width={(isSmall ? 16 : 26) * iconScale} fill='white'>
    <path
      d="M160.746 24.61H23.254C10.432 24.61 0 35.042 0 47.864v88.271c0 12.822 10.432 23.254 23.254 23.254h137.492c12.822 0 23.254-10.432 23.254-23.254V47.864c0-12.822-10.432-23.254-23.254-23.254zm-3.633 15L92 89.909 26.887 39.61h130.226zm3.633 104.78H23.254c-4.551 0-8.254-3.703-8.254-8.254V49.382l72.415 55.94c1.35 1.043 2.968 1.564 4.585 1.564s3.235-.521 4.585-1.564L169 49.382v86.753c0 4.552-3.703 8.255-8.254 8.255z"
    />
  </svg>
  )
};

// const Link = ({ href, children }) => (
//   <a href={ href } target="_blank" className='link'>
//     { children }
//   </a>
// );

const Icon = ({ href, id }) => (
  <div className='icon'>
    { ICONS[id] }
  </div>
);

const BandCamp = ({ isMobile, showTracklist = false }) => {
  const src = isMobile
    ? 'https://bandcamp.com/EmbeddedPlayer/album=503483357/size=small/bgcol=000000/linkcol=ffffff/transparent=0/'
    : `https://bandcamp.com/EmbeddedPlayer/album=503483357/size=large/bgcol=000000/linkcol=ffffff/tracklist=${showTracklist}/artwork=small/transparent=0/`;

  return (
    <iframe
      title='Insane Sends EP by El Choop'
      className={`bandcamp ${isMobile ? 'is-mobile' : ''} ${showTracklist ? 'track-list' : ''}`}
      src={src}
      href="https://echocord.bandcamp.com/album/insane-sends-ep"
      seamless
    />
  );
};

function App() {
  const { width, height } = useWindowSize();

  const isSmallWidthOrHeight = (width < 550) || (height < 750);
  const smallScreen = (height < 650);
  const ultraSlimWidth = (width < 320);
  const ultraSlimHeight = (height < 460);

  const [cursorShift, setCursorShift] = useState({ x: 0, y: 0 });

  const pixelsImageShiftX = width * backgroundShiftPercentage;
  const pixelsImageShiftY = height * backgroundShiftPercentage;

  const onMouseMove = useCallback(throttle((e) => {
    const { width: currentWidth, height: currentHeight } = getWindowSizes();
    
    setCursorShift({ x: (e.clientX / currentWidth).toFixed(2), y: (e.clientY / currentHeight).toFixed(2) })
  }, 25), []);

  useEffect(() => {
    document.addEventListener("mousemove", onMouseMove);

    return () => document.removeEventListener("mousemove", onMouseMove);;
  }, [onMouseMove]);

  const handleKofiLoaded = useCallback(() => {
    window.kofiWidgetOverlay.draw('elchoop', {
      'type': 'floating-chat',
      'floating-chat.donateButton.text': 'Donate',
      'floating-chat.donateButton.background-color': 'black',
      'floating-chat.donateButton.text-color': '#fff'
    });
  }, []);

  useEffect(() => {
    const script = document.createElement('script')
    const body = document.getElementsByTagName('body')[0]
    script.src = 'https://storage.ko-fi.com/cdn/scripts/overlay-widget.js'
    body.appendChild(script)

    script.addEventListener('load', handleKofiLoaded);

    return () => script.removeEventListener('load', handleKofiLoaded);
  }, [handleKofiLoaded]);

  const backgroundImageContainerStyle = useMemo(() => (isDesktopDevice() ? {
    top: `${-(cursorShift.y - 0.5) * pixelsImageShiftY}px`,
    left: `${-(cursorShift.x - 0.5) * pixelsImageShiftX}px`
  } : {}), [cursorShift, pixelsImageShiftX, pixelsImageShiftY]);

  const handleClickBandcamp = () => {
    window.open('https://echocord.bandcamp.com/album/insane-sends-ep', '_blank').focus();
  };

  const handleClickContact = () => {
    window.location.href = "mailto:elchoopmusic@gmail.com?subject=Subject&body=Hey El Choop...";
  };

  return (
    <div className='app'>
      <div className='background-image-container' style={backgroundImageContainerStyle}>
        <div className='background-image-wrapper'>
          <div className='background-image' />
        </div>
      </div>
      <div className='background-pane' />
      <div className={`page ${ultraSlimHeight ? 'ultra-small-height' : ''}`}>
      <div className={`header ${smallScreen ? 'small-screen' : ''} ${ultraSlimHeight ? 'ultra-small-height' : ''}`}>
        <span className='header-text'>{ HEADER }</span>
      </div>
        { !ultraSlimHeight && <div className={`bandcamp-wrapper ${isSmallWidthOrHeight ? 'is-mobile' : ''}`} onClick={handleClickBandcamp}>
          <BandCamp isMobile={isSmallWidthOrHeight} />
        </div> }
        <div className={`icon-links-grid ${smallScreen ? 'small-screen' : ''}`}>
          { LINKS.map(({ href, id }, i) => (
            <a key={id} href={ href } target="_blank" className='link' className={`icon-grid-element ${ i <= 2 ? 'top-row' : ''} ${ i % 3 !== 2 ? 'not-last-column' : ''} ${smallScreen ? 'small-screen' : ''} ${ultraSlimWidth ? 'ultra-slim-width' : ''}`}>
              <Icon id={id} href={ href } />
            </a>
          )) }
        </div>
      </div>
      <div className={`floating-contact ${isSmallWidthOrHeight ? 'small-screen' : ''}`} onClick={handleClickContact}>
        Contact
      </div>
    </div>
  );
}

export default App;
