import Bowser from 'bowser';
import { get } from 'lodash';

// Returns true if the current environment is the browser
// This is used to stop certain actions being run on the server
export const isBrowser = () => !!(
  (typeof window !== 'undefined')
  && (window.document && window.document.createElement)
);

// Returns the width and height of the window
export const getWindowSizes = () => ({
  width: isBrowser() ? window.innerWidth : 0,
  height: isBrowser() ? window.innerHeight : 0
});

// Returns the current browser parser from bowser
export const getBrowserParser = () => {
  return isBrowser()
    ? Bowser.getParser(window.navigator.userAgent)
    : null;
};

// Returns the current browser information as an object
// See https://github.com/lancedikson/bowser
export const getBrowser = () => {
  const browserParser = getBrowserParser();

  return browserParser
    ? browserParser.getResult()
    : {};
};


// Uses a test object to decide if the browser meets certain conditions
// See https://github.com/lancedikson/bowser
export const satisfiesBrowser = (testObj) => {
  const browserParser = getBrowserParser();

  return browserParser
    ? browserParser.satisfies(testObj)
    : false;
};

// Returns true if the device is a desktop device
export const isDesktopDevice = () => {
  const browser = getBrowser();
  const platformType = get(browser, 'platform.type', null);

  return platformType ? (platformType === 'desktop') : null;
};