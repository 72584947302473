import {
  useState, useEffect, useRef, useCallback
} from 'react';
import { isEqual } from 'lodash';

// HELPERS
import { truthyOrZero } from '../../helpers/math';

const useDebounce = (initialValue, debounceDelay) => {
  const delay = (debounceDelay || (debounceDelay === 0)) ? debounceDelay : 500;

  const intervalRef = useRef(null);
  const debouncedValue = useRef(initialValue);
  const currentValue = useRef(initialValue);
  const [updaterCount, setUpdateCount] = useState(0);

  const clearDebounceInterval = () => {
    clearInterval(intervalRef.current);
  };

  const setDebounceInterval = useCallback((customDelay) => {
    clearDebounceInterval();

    intervalRef.current = setInterval(() => {
      if (!isEqual(debouncedValue.current, currentValue.current)) {
        debouncedValue.current = currentValue.current;
        setUpdateCount(updaterCount + 1);
      }
    }, truthyOrZero(customDelay) ? customDelay : delay);
  }, [delay, updaterCount]);

  const handleSetValue = useCallback((newValue, isImmediate = false) => {
    if (delay) {
      setDebounceInterval(isImmediate ? 0 : undefined);

      currentValue.current = newValue;
    } else {
      clearDebounceInterval();

      debouncedValue.current = newValue;
      setUpdateCount(updaterCount + 1);
    }
  }, [delay, setDebounceInterval, updaterCount]);

  const handleSetImmediately = useCallback((newValue) => {
    handleSetValue(newValue, true);
  }, [handleSetValue]);

  useEffect(() => {
    clearDebounceInterval();
  },
  [delay, updaterCount]);

  return {
    debouncedValue: debouncedValue.current,
    set: handleSetValue,
    setImmediately: handleSetImmediately
  };
};

export default useDebounce;