import { useEffect } from 'react';

// HELPERS
import { getWindowSizes } from '../../helpers/browser';

// HOOKS
import useDebounce from '../utility/useDebounce';

const useWindowSize = ({ debounceTime = 250 } = {}) => {
  const { debouncedValue: debouncedWindowSize, set: setWindowSize } = useDebounce(getWindowSizes(), debounceTime);

  useEffect(() => {
    const handleWindowResize = () => setWindowSize(getWindowSizes());

    window.addEventListener('resize', handleWindowResize);
    
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [debounceTime, setWindowSize]);

  return debouncedWindowSize;
};

export default useWindowSize;