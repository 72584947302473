// Convert an arc length on a circle's circumference
// to the degree (in radians) at the center of the circle
export const convertArcLengthToRadians = (arcLenth, radius) => {
  return arcLenth / radius;
};

// Inverse of the above
export const convertRadiansToArcLength = (radians, radius) => {
  return radians * radius;
};

export const randomInteger = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const randomNumber = (min, max) => {
  return Math.random() * (max - min) + min;
}

// Returns true if a value is either truthy OR zero
export const truthyOrZero = (value) => {
  return !!value || (value === 0);
};

export const putNumberInRange = (num, lower, upper) => {
  if (num < lower) { return lower; }
  if (num > upper) { return upper; }

  return num;
};